exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-classes-js": () => import("./../../../src/pages/about/classes.js" /* webpackChunkName: "component---src-pages-about-classes-js" */),
  "component---src-pages-about-hakham-js": () => import("./../../../src/pages/about/hakham.js" /* webpackChunkName: "component---src-pages-about-hakham-js" */),
  "component---src-pages-about-yafe-beito-js": () => import("./../../../src/pages/about/yafe-beito.js" /* webpackChunkName: "component---src-pages-about-yafe-beito-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-audio-player-custom-js": () => import("./../../../src/pages/audioPlayerCustom.js" /* webpackChunkName: "component---src-pages-audio-player-custom-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-book-shop-js": () => import("./../../../src/pages/book-shop.js" /* webpackChunkName: "component---src-pages-book-shop-js" */),
  "component---src-pages-classes-js": () => import("./../../../src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

